import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { onPageNavigationEvent, placementClickEvent } from 'src/lib/analytics';

import styles from './Button.module.scss';

const Button = ({
  onClick,
  category,
  outline,
  children,
  clickLocation,
  clickTitle,
  clickPosition,
  clickColumn,
  disabled,
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (clickLocation && clickTitle) {
      onPageNavigationEvent(clickLocation, 'button', clickTitle);
    }

    if (clickLocation && clickTitle && clickPosition && clickColumn) {
      placementClickEvent(
        clickLocation,
        clickPosition,
        clickTitle,
        clickColumn
      );
    }
  };

  const buttonVariant = outline
    ? styles[`button--outline-${category}`]
    : styles[`button--${category}`];
  return (
    <button
      type="button"
      className={classNames(styles.button, buttonVariant)}
      onClick={() => handleClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  category: PropTypes.string.isRequired,
  outline: PropTypes.bool,
  children: PropTypes.node.isRequired,
  clickLocation: PropTypes.string,
  clickTitle: PropTypes.string,
  clickPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clickColumn: PropTypes.string,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  outline: false,
  clickLocation: '',
  clickTitle: '',
  clickPosition: null,
  clickColumn: '',
  disabled: false,
};

export default Button;
