import React from 'react';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import Card from 'src/components/card/Card';

import styles from './Recommendations.module.scss';

const Recommendations = ({ content }) => (
  <div className={styles.recommendations}>
    <h3 className={styles.recommendations__header}>
      Next up: More trusted advice from...
    </h3>
    {content.map((column, index) => (
      <div
        key={uuidv4()}
        className={classNames(styles.recommendations__card, {
          [styles.recommendations__card_drop]: index >= 2,
        })}
      >
        <Card
          headshotSize="sm"
          feature={column.feature}
          articles={column.articles}
          clickLocation="recommended"
          clickPosition={index + 1}
          trackPosition
        />
      </div>
    ))}
  </div>
);

Recommendations.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      feature: PropTypes.shape({
        featureName: PropTypes.string.isRequired,
        featureShortName: PropTypes.string.isRequired,
        featureAvatarUrls: PropTypes.shape({
          small: PropTypes.string.isRequired,
        }).isRequired,
        creators: PropTypes.arrayOf(
          PropTypes.shape({
            fullName: PropTypes.string.isRequired,
          }).isRequired
        ).isRequired,
        category: PropTypes.string.isRequired,
      }).isRequired,
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          publishDate: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
};

export default Recommendations;
